<script lang="ts">
  import { createBoard as createBoardRequest } from '../api-calls';
  import ExampleList from '../components/example-list.svelte';
  import Button from '../components/button.svelte';
  import { examples } from '../examples';
  import { route, usedBoards } from '../stores';

  async function createBoard(evt: any) {
    // fixme
    evt.preventDefault();
    const res = await createBoardRequest();
    if (res.success) {
      route.setRoute({ route: 'board', id: res.data });
    }
    // fixme: handle errors
  }
</script>

<svelte:head>
  <link rel="icon" type="image/svg+xml" href="/alert-triangle.svg" />
</svelte:head>

<div class="layout-container">
  <h1>incident.day</h1>

  <main>
    <div>
      <p>
        incident.day let's you set up boards to track how long there is since
        something happened, or how long there is until something happens. Check
        out examples below or look at the <a
          href="/help"
          on:click|preventDefault={() => {
            route.setRoute({ route: 'help' });
          }}>help page.</a
        >
      </p>
    </div>

    <h3>Make your own board</h3>

    <p>
      <Button on:click={createBoard}>Create new board</Button>
    </p>

    {#if $usedBoards.length > 0}
      <h3>
        Previously used
        <a
          class="forget-link"
          href="/"
          on:click|preventDefault={() => usedBoards.clear()}>(forget them)</a
        >
      </h3>

      <ExampleList>
        {#each $usedBoards.slice(0, 5) as board}
          <li>
            <a
              href="/board/{board.id}"
              on:click|preventDefault={() =>
                route.setRoute({ route: 'board', id: board.id })}
              >{board.name}</a
            >
          </li>
        {/each}
      </ExampleList>
    {/if}

    <div>
      <h3>Examples</h3>
      <div>
        {#each examples as example, index}
          <div class="example">
            <a
              href="/board/{example.id}"
              on:click|preventDefault={() =>
                route.setRoute({ route: 'board', id: example.id })}
            >
              {example.label}
            </a>
            {#if index < examples.length - 1}
              <span> &bull; </span>
            {/if}
          </div>
        {/each}
      </div>
    </div>
  </main>
</div>

<footer>
  <a
    href="/about"
    on:click|preventDefault={() => {
      route.setRoute({ route: 'about' });
    }}>About and licenses</a
  >
</footer>

<style>
  .layout-container {
    max-width: 650px;
    margin: auto;
  }

  .forget-link {
    color: gray;
    font-size: 70%;
  }

  h1 {
    font-size: 90px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .example {
    display: inline;
  }

  .example a {
    color: black;
  }

  footer a {
    color: black;
  }

  footer {
    margin: auto;
    margin-top: 50px;
    font-size: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
</style>
