<script>
  import { scale } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  let hovering = false;

  const enter = () => (hovering = true);
  const leave = () => (hovering = false);
</script>

<div class="label" on:mouseenter={enter} on:mouseleave={leave}>
  Help for values in text boxes
  {#if hovering}
    <div
      class="container"
      in:scale={{ duration: 150, easing: quintOut, opacity: 0 }}
    >
      The following codes can be used in the text boxes:

      <ul>
        <li>
          <code>&lcub;seconds&rcub;</code> - Number of seconds since or to the board
          time. Can be negative.
        </li>
        <li>
          <code>&lcub;minutes&rcub;</code> - Number of minutes since or to the board
          time. Can be negative.
        </li>
        <li>
          <code>&lcub;hours&rcub;</code> - Number of hours since or to the board
          time. Can be negative.
        </li>
        <li>
          <code>&lcub;days&rcub;</code> - Number of days since or to the board time.
          Can be negative.
        </li>
        <li>
          <code>&lcub;weeks&rcub;</code> - Number of weeks since or to the board
          time. Can be negative.
        </li>
        <li>
          <code>&lcub;months&rcub;</code> - Number of months since or to the board
          time. Can be negative.
        </li>
        <li>
          <code>&lcub;seconds&rcub;</code> - Absolute number of seconds since or
          to the board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;minutes&rcub;</code> - Absolute number of minutes since or
          to the board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;hours&rcub;</code> - Absolute number of hours since or to the
          board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;days&rcub;</code> - Absolute number of days since or to the
          board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;weeks&rcub;</code> - Absolute number of weeks since or to the
          board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;months&rcub;</code> - Absolute number of months since or to
          the board time. Will never be negative.
        </li>
        <li>
          <code>&lcub;utcIsoTime&rcub;</code> - Board time in UTC rendered in ISO
          format.
        </li>
        <li>
          <code>&lcub;utcIsoDate&rcub;</code> - Board date in UTC rendered in ISO
          format.
        </li>
        <li>
          <code>&lcub;localIsoTime&rcub;</code> - Board time in browsers time zone
          rendered in ISO format.
        </li>
        <li>
          <code>&lcub;localIsoDate&rcub;</code> - Board date in browsers time zone
          rendered in ISO format.
        </li>
        <li>
          <code>&lcub;localeDateTime&rcub;</code> - Board date and time rendered
          in display locale.
        </li>
        <li>
          <code>&lcub;localeDate&rcub;</code> - Board date rendered in display locale.
        </li>
        <li>
          <code>&lcub;localeTime&rcub;</code> - Board time rendered in display locale.
        </li>
        <li>
          <code>&lcub;localeNowDateTime&rcub;</code> - current date and time rendered
          in display locale.
        </li>
        <li>
          <code>&lcub;localeNowDate&rcub;</code> - current date rendered in display
          locale.
        </li>
        <li>
          <code>&lcub;localeNowTime&rcub;</code> - current time rendered in display
          locale.
        </li>
        <li>
          <code>:thumbsup:</code> - The "thumbs up" emoji. This works with most emoji
          names
        </li>
      </ul>
    </div>
  {/if}
</div>

<style>
  .label {
    display: inline;
    text-decoration: underline;
  }

  .container {
    left: 60px;
    top: 10px;
    min-width: 600px;
    max-width: 1200px;
    background-color: white;
    position: absolute;
    border: 0.1rem solid var(--bg-color);
    border-radius: var(--border-radius);
    margin: 12px;
    padding: 12px;
    font-size: small;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
</style>
