<script lang="ts">
  export let kind: 'ol' | 'ul' = 'ul';
</script>

{#if kind === 'ol'}
  <ol>
    <slot />
  </ol>
{:else}
  <ul>
    <slot />
  </ul>
{/if}

<style>
  ol,
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: circle inside;
  }

  ol,
  ul {
    margin-bottom: 2.5rem;
  }
</style>
