<div class="container">
  <h1>incident.day</h1>

  <div>
    <a href="/">Back</a>
  </div>

  <h2>Help</h2>

  <p>
    The boards can customized with text, fonts and colors. There is also an HTTP
    API, to set or reset the time of the board.
  </p>

  <h3>Basics</h3>

  <p><img class="screenshot" alt="" src="/settings.png" /></p>

  <h3>Text placeholders</h3>

  The following placeholders can be used in the boards, and will be replaced by
  the appropriate value.

  <ul>
    <li>
      <code>&lcub;seconds&rcub;</code> - Number of seconds since or to the board
      time. Can be negative.
    </li>
    <li>
      <code>&lcub;minutes&rcub;</code> - Number of minutes since or to the board
      time. Can be negative.
    </li>
    <li>
      <code>&lcub;hours&rcub;</code> - Number of hours since or to the board time.
      Can be negative.
    </li>
    <li>
      <code>&lcub;days&rcub;</code> - Number of days since or to the board time.
      Can be negative.
    </li>
    <li>
      <code>&lcub;weeks&rcub;</code> - Number of weeks since or to the board time.
      Can be negative.
    </li>
    <li>
      <code>&lcub;months&rcub;</code> - Number of months since or to the board time.
      Can be negative.
    </li>
    <li>
      <code>&lcub;seconds&rcub;</code> - Absolute number of seconds since or to the
      board time. Will never be negative.
    </li>
    <li>
      <code>&lcub;minutes&rcub;</code> - Absolute number of minutes since or to the
      board time. Will never be negative.
    </li>
    <li>
      <code>&lcub;hours&rcub;</code> - Absolute number of hours since or to the board
      time. Will never be negative.
    </li>
    <li>
      <code>&lcub;days&rcub;</code> - Absolute number of days since or to the board
      time. Will never be negative.
    </li>
    <li>
      <code>&lcub;weeks&rcub;</code> - Absolute number of weeks since or to the board
      time. Will never be negative.
    </li>
    <li>
      <code>&lcub;months&rcub;</code> - Absolute number of months since or to the
      board time. Will never be negative.
    </li>
    <li>
      <code>&lcub;utcIsoTime&rcub;</code> - Board time in UTC rendered in ISO format.
    </li>
    <li>
      <code>&lcub;utcIsoDate&rcub;</code> - Board date in UTC rendered in ISO format.
    </li>
    <li>
      <code>&lcub;localIsoTime&rcub;</code> - Board time in browsers time zone rendered
      in ISO format.
    </li>
    <li>
      <code>&lcub;localIsoDate&rcub;</code> - Board date in browsers time zone rendered
      in ISO format.
    </li>
    <li>
      <code>&lcub;localeDateTime&rcub;</code> - Board date and time rendered in display
      locale.
    </li>
    <li>
      <code>&lcub;localeDate&rcub;</code> - Board date rendered in display locale.
    </li>
    <li>
      <code>&lcub;localeTime&rcub;</code> - Board time rendered in display locale.
    </li>
    <li>
      <code>&lcub;localeNowDateTime&rcub;</code> - current date and time rendered
      in display locale.
    </li>
    <li>
      <code>&lcub;localeNowDate&rcub;</code> - current date rendered in display locale.
    </li>
    <li>
      <code>&lcub;localeNowTime&rcub;</code> - current time rendered in display locale.
    </li>
    <li>
      <code>:thumbsup:</code> - The "thumbs up" emoji. This works with most emoji
      names
    </li>
  </ul>

  <h3>Read-only URLs</h3>

  The read-only URL of a board can be used to share it or display it in public
  places without worrying about people changing the contents.

  <h3>API</h3>

  The API URL of a board can be used to change the time of the board. For
  example to reset the time of a deployment via a webhook. Posting to the API
  URL sets the time to the current time. Optionally, include a JSON body in the
  request to set the time to a specific value. The time must be in ISO format:

  <pre><code
      >&lbrace; "time": "2022-05-16T11:10:34.786Z" &rbrace;
</code></pre>

  <h3>Terms</h3>

  <p>Using incident.day is free and anonymous, but there is no SLA.</p>
</div>

<style>
  .container {
    max-width: 650px;
    margin: auto;
  }

  .screenshot {
    max-width: 640px;
  }

  h1 {
    font-size: 90px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
</style>
