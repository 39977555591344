<script lang="ts">
  import { defaultFont, defaultIconEmoji } from '../lib/consts';
  import { formatString, getBoardName } from '../lib/format';
  import { board, clock } from '../stores';
  import { getFaviconDataUri } from '../lib/favicon';

  $: iconEmoji = $board.iconEmoji ?? defaultIconEmoji;
  $: faviconDataUri = getFaviconDataUri(iconEmoji);
  $: title = getBoardName($board, false);
</script>

<div
  id="container"
  style:background-color={$board.backgroundColor ?? 'white'}
  style:color={$board.textColor ?? 'black'}
  style:font-family={$board.font ?? defaultFont.family}
>
  {#each $board.lines as line}
    <div style:font-size={`${line.size}%`} style:transition="font-size .1s">
      {formatString(line.value, $board, $clock)}
    </div>
  {/each}
</div>

<svelte:head>
  <meta name="theme-color" content={$board.backgroundColor} />
  <link rel="icon" type="image/svg+xml" href={faviconDataUri} />
  <title>{title}</title>
</svelte:head>

<style>
  #container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    font-size: 22px;
  }

  @media (max-width: 100em) {
    #container {
      font-size: 20px;
    }
  }

  @media (max-width: 90em) {
    #container {
      font-size: 18px;
    }
  }

  @media (max-width: 80em) {
    #container {
      font-size: 16px;
    }
  }

  @media (max-width: 70em) {
    #container {
      font-size: 14px;
    }
  }

  @media (max-width: 60em) {
    #container {
      font-size: 12px;
    }
  }

  @media (max-width: 50em) {
    #container {
      font-size: 10px;
    }
  }

  @media (max-width: 40em) {
    #container {
      font-size: 8px;
    }
  }

  @media (max-width: 30em) {
    #container {
      font-size: 6px;
    }
  }
</style>
