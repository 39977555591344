<script lang="ts">
  import { fade } from 'svelte/transition';
  import { makeDeadlineExpiredStore } from '../lib/store-creators';

  const isIdle = makeDeadlineExpiredStore(3000);
</script>

<svelte:window on:pointermove={isIdle.reset} on:pointerdown={isIdle.reset} />

{#if $isIdle === false}
  <div transition:fade|local>
    <slot />
  </div>
{/if}
