<script lang="ts">
  import { board, editorVisible, route, usedBoards } from '../stores';
  import { ArrowDownIcon, ArrowUpIcon } from 'svelte-feather-icons';
  import { saveBoard, deleteBoard } from '../api-calls';
  import {
    defaultBackgroundColor,
    defaultColor,
    defaultFont,
    fonts,
    locales,
    timeZones,
  } from '../lib/consts';
  import { hex, score } from 'wcag-contrast';
  import { stringIsEmoji } from '../lib/favicon';
  import FontSelect from './font-select.svelte';
  import { isoFormatLocalTime } from '../lib/time';
  import InterpolationHelp from './interpolation-help.svelte';
  import Button from './button.svelte';

  let hovering: number | null = null;
  let localTime = isoFormatLocalTime(new Date($board.resetTime));
  let fontFamily = fonts.find((e) => e.family === $board.font) ?? defaultFont;
  let textColor = $board.textColor ?? defaultColor;
  let backgroundColor = $board.backgroundColor ?? defaultBackgroundColor;
  let iconEmoji = $board.iconEmoji ?? '';
  let displayTimeZone = $board.displayTimeZone ?? undefined;
  let displaylocale = $board.displaylocale ?? undefined;

  function onCancel() {
    board.revert();
    editorVisible.toggle();
  }

  async function onSave() {
    board.save();
    await saveBoard($board);
    usedBoards.add($board);
    editorVisible.toggle();
  }

  async function onDelete() {
    if (confirm('Delete the board?')) {
      await deleteBoard($board);
      usedBoards.delete($board.id);
      route.replaceRoute({ route: 'index' });
    }
  }

  function onKeyUp(evt: KeyboardEvent) {
    if (evt.key === 'Escape') {
      onCancel();
    }
  }

  function drop(event: DragEvent, target: string) {
    const start = Number(event.dataTransfer.getData('text/plain'));
    const end = Number(target);
    board.moveLine(start, end);
    hovering = null;
  }

  function dragstart(event: DragEvent, i: number) {
    event.dataTransfer.setData('text/plain', String(i));
  }

  function onClickNow() {
    localTime = isoFormatLocalTime(new Date());
  }

  $: {
    $board.resetTime = new Date(localTime).toUTCString();
    $board.font = fontFamily.family;
    $board.textColor = textColor;
    $board.backgroundColor = backgroundColor;
    $board.displaylocale = displaylocale;
    $board.displayTimeZone = displayTimeZone;

    // If there are two empty lines at the end, remove the last one
    if ($board.lines.length > 2) {
      const nextToLastLine = $board.lines[$board.lines.length - 2];
      const lastLine = $board.lines[$board.lines.length - 1];
      if (lastLine.value.trim() === '' && nextToLastLine.value.trim() === '') {
        $board.lines.splice($board.lines.length - 1, 1);
      }
    }

    // Make sure there is always a blank line at the end.
    if ($board.lines.length > 1) {
      const lastLine = $board.lines[$board.lines.length - 1];
      if (lastLine.value.trim() !== '') {
        board.addLine();
      }
    }

    if (stringIsEmoji(iconEmoji.trim())) {
      $board.iconEmoji = iconEmoji.trim();
    } else if (iconEmoji.trim() === '') {
      $board.iconEmoji = undefined;
    }
  }

  $: contrastScore = score(hex(backgroundColor, textColor));
</script>

<svelte:window on:keyup={onKeyUp} />

<div class="editor">
  <div class="inner-container">
    <label for="datetime">Time:</label>
    <div class="two-cell-row">
      <input type="datetime-local" id="datetime" bind:value={localTime} />
      <Button on:click={onClickNow} kind="outline">Now</Button>
    </div>
  </div>

  <div class="inner-container">
    <label for="datetime">Text:</label>
    {#each $board.lines as line, index (index)}
      <div
        class="list-item"
        draggable={true}
        on:dragstart={(event) => dragstart(event, index)}
        on:drop|preventDefault={(event) => drop(event, index.toString())}
        on:dragover|preventDefault={(e) => (e.dataTransfer.dropEffect = 'move')}
        on:dragenter={() => (hovering = index)}
        class:is-active={hovering === index}
      >
        <span
          draggable="true"
          on:dragstart|preventDefault|stopPropagation={() => {}}
        >
          <input
            class="line-input"
            autocomplete="off"
            type="text"
            name="preTitle"
            bind:value={line.value}
            placeholder={index === $board.lines.length - 1
              ? 'New line'
              : 'Delete this line when saving'}
            style:width="100%"
          />
        </span>

        <input
          draggable="true"
          on:dragstart|preventDefault|stopPropagation={() => {}}
          class="font-input"
          type="range"
          min="150"
          max="2400"
          bind:value={line.size}
        />
        <span class="handle">
          <ArrowUpIcon size="14" /><ArrowDownIcon size="14" />
        </span>
      </div>
    {/each}

    <InterpolationHelp />
  </div>

  <div class="inner-container">
    <div class="color-row">
      <label for="textColor">Text color:</label>
      <input id="textColor" type="color" bind:value={textColor} />
    </div>

    <div class="color-row">
      <label for="backgroundColor">Background color:</label>
      <input id="backgroundColor" type="color" bind:value={backgroundColor} />
    </div>

    {#if contrastScore === 'Fail'}
      <div class="contrast-warning">Poor contrast!</div>
    {/if}
  </div>

  <div class="inner-container">
    <details>
      <summary>More settings</summary>
      <div>
        <label for="font-selector-input">Font: </label>
        <div class="input-row">
          <FontSelect bind:value={fontFamily} {fonts} />
        </div>

        <div class="input-row">
          <label for="icon"
            >Icon: <span class="emoji-info">Use an emoji</span>
          </label>
          <input id="icon" type="text" bind:value={iconEmoji} size="1" />

          {#if iconEmoji.trim() !== '' && !stringIsEmoji(iconEmoji.trim())}
            <span class="wrong-icon-warning">Must be an emoji.</span>
          {/if}
        </div>

        <div class="input-row">
          <label for="time-zone">Display time-zone</label>
          <select id="time-zone" bind:value={displayTimeZone}>
            <option value={undefined} selected={displaylocale === undefined}
              >None (use browser default)</option
            >
            {#each timeZones as timeZone}
              <option value={timeZone} selected={displayTimeZone === timeZone}
                >{timeZone}</option
              >
            {/each}
          </select>
        </div>

        <div class="input-row">
          <label for="locale">Display locale</label>
          <select id="locale" bind:value={displaylocale}>
            <option value={undefined} selected={displaylocale === undefined}
              >None (use browser default)</option
            >
            {#each locales as locale}
              <option
                value={locale.name}
                selected={displaylocale === locale.name}>{locale.label}</option
              >
            {/each}
          </select>
        </div>
      </div>
    </details>
  </div>

  <div class="button-row">
    <div>
      <Button kind="outline" on:click={onDelete}>Delete</Button>
    </div>
    <div>
      <Button kind="outline" on:click={onCancel}>Cancel</Button>
      <Button on:click={onSave}>Save</Button>
    </div>
  </div>
</div>

<style>
  .editor {
    text-align: left;
  }

  .inner-container {
    padding: 12px;
    border-bottom: solid 1px var(--bg-color);
  }

  .list-item {
    margin-bottom: 6px;
    padding: 0;
    display: grid;
    grid-template-columns: 2fr 1fr auto;
    gap: 4px;
  }

  .list-item.is-active {
    background-color: #3273dc;
    color: #fff;
    outline: 4px solid #3273dc;
  }

  .wrong-icon-warning {
    color: red;
  }

  .button-row {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    display: flex;
    flex: 1;
  }

  .button-row *:nth-child(2) {
    flex: 1;
    text-align: right;
  }

  .color-row {
    display: flex;
    align-items: center;
  }

  .color-row *:nth-child(1) {
    flex: 1;
  }

  .color-row *:nth-child(2) {
    flex: 1;
  }

  .two-cell-row {
    display: flex;
    gap: 12px;
  }

  .handle {
    cursor: grab;
  }

  .emoji-info {
    font-size: smaller;
    font-weight: normal;
  }

  .input-row {
    padding-bottom: 12px;
  }

  /* start milligram */

  input[type='color'],
  input[type='datetime-local'],
  input[type='text'],
  input:not([type]),
  select {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0.1rem solid #d1d1d1;
    border-radius: 0.4rem;
    box-shadow: none;
    box-sizing: inherit;
    height: 3.8rem;
    padding: 0.6rem 1rem 0.7rem;
    width: 100%;
  }

  input[type='color']:focus,
  input[type='datetime-local']:focus,
  input[type='text']:focus,
  input:not([type]):focus,
  select:focus {
    border-color: #9b4dca;
    outline: 0;
  }

  select {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>')
      center right no-repeat;
    padding-right: 3rem;
  }

  select:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%239b4dca" d="M0,0l6,8l6-8"/></svg>');
  }

  label {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  /* End milligram */

  /* Applies to anything child of this component with class "feather" */
  * :global(.feather) {
    vertical-align: middle;
  }
</style>
