<script lang="ts">
  import AboutRoute from './routes/about.svelte';
  import BoardRoute from './routes/board.svelte';
  import HelpRoute from './routes/help.svelte';
  import IndexRoute from './routes/frontpage.svelte';
  import NotFoundRoute from './routes/not-found.svelte';
  import { route } from './stores';
  import type { RedableRoute } from './lib/router';
  import type { SvelteComponent } from 'svelte';

  const routeComponents: Record<
    RedableRoute['route'],
    new (...args: any[]) => SvelteComponent
  > = {
    'not-found': NotFoundRoute,
    about: AboutRoute,
    board: BoardRoute,
    index: IndexRoute,
    help: HelpRoute,
  };

  $: currentComponent = routeComponents[$route.route];
</script>

<svelte:component this={currentComponent} />
