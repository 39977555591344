<script lang="ts">
  export let draggableLeft = 0;
  export let draggableTop = 0;

  let startX = 0;
  let startY = 0;

  const onPointerDown: svelte.JSX.PointerEventHandler<HTMLElement> = (e) => {
    e.target.setPointerCapture(e.pointerId);
    document.body.style.cursor = 'move';
    startX = e.offsetX;
    startY = e.offsetY;
  };

  const onPointerMove: svelte.JSX.PointerEventHandler<HTMLElement> = (e) => {
    if (e.target.hasPointerCapture(e.pointerId)) {
      // -1 for is for border width
      draggableLeft = e.clientX - startX - 1;
      draggableTop = e.clientY - startY - 1;
    }
  };

  const onPointerUp: svelte.JSX.PointerEventHandler<HTMLElement> = () => {
    document.body.style.cursor = '';
  };
</script>

<section
  style:transform="translate({draggableLeft}px, {draggableTop}px)"
  class="draggable"
>
  <div
    class="title"
    on:pointerdown|preventDefault={onPointerDown}
    on:pointermove={onPointerMove}
    on:pointerup={onPointerUp}
    on:touchstart={(e) => {
      // Prevent selection start on iOS/iPadOS
      e.preventDefault();
    }}
  >
    <slot name="title" />
  </div>

  <slot />
</section>

<style>
  .draggable {
    width: 450px;
    border-radius: var(--border-radius);
    border: 0.1rem solid var(--bg-color);
    background-clip: padding-box;
    background-color: white;
    transition: opacity 0.5s;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }

  .draggable:hover {
    opacity: 1;
  }

  .title {
    border-bottom: solid thin var(--bg-color);
    padding: 8px;
    cursor: move;
  }
</style>
