<script lang="ts">
  import { board } from '../stores';

  $: apiUrl =
    $board.kind === 'writable'
      ? new URL(`/set-time/${$board.apiId}`, window.location.href)
      : null;

  $: readonlyUrl =
    $board.kind === 'writable'
      ? new URL(`/board/${$board.readonlyId}`, window.location.href)
      : null;
</script>

<div id="container">
  {#if $board.kind === 'writable'}
    <div>
      <label for="readonly-url">Read-only URL</label>
      <button
        class="copy-button"
        on:click={async () => {
          await navigator.clipboard.writeText(readonlyUrl.href);
        }}>(copy to clipboard)</button
      >
      <input id="readonly-url" type="text" readonly bind:value={readonlyUrl} />
      <small>This URL shows the board, but doesn't allow editing.</small>
    </div>
    <hr />
    <div>
      <label for="api-url">API URL</label>
      <button
        type="button"
        class="copy-button"
        on:click={async () => {
          await navigator.clipboard.writeText(apiUrl.href);
        }}>(copy to clipboard)</button
      >
      <input id="api-url" type="text" readonly bind:value={apiUrl} />
      <small>This URL is used to change the board programatically.</small>
    </div>
  {:else}
    <div>
      This is a read-only version of this board. If you need to change it, ask
      the person who made it to get an edit URL.
    </div>
  {/if}
</div>

<style>
  #container {
    text-align: left;
    width: 380px;
    top: 8px;
    left: 8px;
    position: fixed;
    border-radius: 6px;
    border: solid 1px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    padding: 12px;
    background-color: white;
    color: black;
  }

  input {
    width: 100%;
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    margin: var(--input-margin);
    padding: var(--input-padding);
    font-size: var(--input-font-size);
  }

  .copy-button {
    border: none;
    background-color: transparent;
    text-decoration: underline;
  }
</style>
