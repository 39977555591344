<script lang="ts">
  type ButtonKind = 'primary' | 'outline' | 'clear';

  export let kind: ButtonKind = 'primary';
  export let disabled = false;

  $: cls =
    kind === 'primary'
      ? 'button'
      : kind === 'outline'
      ? 'button button-outline'
      : 'button button-clear';
</script>

<button on:click type="button" class={cls} {disabled}><slot /></button>

<style>
  .button {
    background-color: var(--bg-color);
    border: 0.1rem solid var(--bg-color);
    border-radius: var(--border-radius);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 700;
    height: 3.8rem;
    letter-spacing: 0.1rem;
    line-height: 3.8rem;
    padding: 0 3rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .button:focus,
  .button:hover {
    background-color: var(--hover-bg-color);
    border-color: var(--hover-bg-color);
    color: #fff;
    outline: 0;
  }

  .button[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  .button[disabled]:focus,
  .button[disabled]:hover {
    background-color: var(--bg-color);
    border-color: var(--bg-color);
  }

  .button.button-outline {
    background-color: transparent;
    color: var(--bg-color);
  }

  .button.button-outline:focus,
  .button.button-outline:hover {
    background-color: transparent;
    border-color: var(--hover-bg-color);
    color: var(--hover-bg-color);
  }

  .button.button-outline[disabled]:focus,
  .button.button-outline[disabled]:hover {
    border-color: inherit;
    color: var(--bg-color);
  }

  .button.button-clear {
    background-color: transparent;
    border-color: transparent;
    color: var(--bg-color);
  }

  .button.button-clear:focus,
  .button.button-clear:hover {
    background-color: transparent;
    border-color: transparent;
    color: var(--hover-bg-color);
  }

  .button.button-clear[disabled]:focus,
  .button.button-clear[disabled]:hover {
    color: var(--bg-color);
  }

  .button {
    margin-bottom: 1rem;
  }
</style>
