<script lang="ts">
  import { fade } from 'svelte/transition';
  import BoardDisplay from '../components/board-display.svelte';
  import Disappearable from '../components/disappearable.svelte';
  import Draggable from '../components/draggable.svelte';
  import {
    editorVisible,
    board,
    refetchCountdownExpired,
    reloadCountdownExpired,
  } from '../stores';
  import { route, usedBoards } from '../stores';
  import { onMount } from 'svelte';
  import { fetchBoard } from '../api-calls';
  import BoardInfo from '../components/board-info.svelte';
  import WritableEditor from '../components/writable-editor.svelte';
  import Button from '../components/button.svelte';

  let state: 'loading' | 'ready' | 'not-found' | 'error';
  let draggableLeft = 8;
  let draggableTop = 8;

  function getBoardId() {
    return window.location.pathname.split('/').pop();
  }

  async function loadBoard() {
    const id = getBoardId();
    const res = await fetchBoard(id);
    return res;
  }

  async function handleRefetch() {
    await loadBoard();
    refetchCountdownExpired.reset();
  }

  onMount(async () => {
    const res = await loadBoard();

    if (res.success === true) {
      $board = res.data;
      board.save();
      usedBoards.add($board);
      state = 'ready';
    } else if (res.status === 404) {
      state = 'not-found';
    } else {
      state = 'error';
    }
  });

  $: {
    if ($refetchCountdownExpired) {
      if ($editorVisible) {
        refetchCountdownExpired.reset();
      } else {
        handleRefetch();
      }
    }
  }

  $: {
    if ($reloadCountdownExpired && !$editorVisible) {
      window.location.reload();
    }
  }
</script>

{#if state === 'not-found'}
  <div class="not-found-container">
    <h3>Board not found</h3>
    <p>The board ID <code>{getBoardId()}</code> doesn't seem to exist.</p>
    <p>
      <a
        href="/"
        on:click|preventDefault={() => route.setRoute({ route: 'index' })}
        >Go to front page</a
      >
    </p>
  </div>
{:else if state === 'ready'}
  <main>
    {#if $editorVisible === false}
      <Disappearable>
        <div class="controls">
          <Button
            kind="outline"
            on:click={(evt) => {
              evt.preventDefault();
              route.setRoute({ route: 'index' });
            }}>Back</Button
          >

          <Button
            on:click={editorVisible.toggle}
            disabled={$board.kind !== 'writable'}>Edit</Button
          >
        </div>
        <BoardInfo />
      </Disappearable>
    {/if}

    <BoardDisplay />
    {#if $editorVisible}
      <div transition:fade>
        <Draggable bind:draggableLeft bind:draggableTop>
          <span slot="title">Board settings</span>
          <WritableEditor />
        </Draggable>
      </div>
    {/if}
  </main>
{:else}
  <div />
{/if}

<style>
  main {
    text-align: center;
    margin: 0 auto;
  }

  .controls {
    position: fixed;
    top: 0;
    right: 0;
    margin: 6px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

  .not-found-container {
    text-align: center;
    max-width: 650px;
    margin: auto;
  }
</style>
