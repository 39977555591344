<script lang="ts" context="module">
  export type FontItem = { family: string; name: string };
</script>

<script lang="ts">
  export let value: FontItem;
  export let fonts: FontItem[];

  // fixme: look into how to make label tags outside activate this properly
  let open = false;
  let inputWidth = 0;
  let container: HTMLDivElement;
  let input: HTMLInputElement;
  let selectedIndex = 0;

  function close() {
    open = false;
    input.blur();
  }

  function onClick(evt: MouseEvent) {
    if (!open) {
      return;
    }

    if (container && evt.target) {
      // fixme: the any
      if (!container.contains(evt.target as any)) {
        evt.preventDefault();
        close();
      }
    }
  }

  function onKeyUp(evt: KeyboardEvent) {
    if (!open) {
      return;
    }

    if (evt.key === 'Escape') {
      evt.preventDefault();
      evt.stopPropagation();
      close();
    } else if (evt.key === 'ArrowUp' || evt.key === 'Up') {
      selectedIndex = selectedIndex === 0 ? selectedIndex : selectedIndex - 1;
      evt.preventDefault();
      evt.stopPropagation();
    } else if (evt.key === 'ArrowDown' || evt.key === 'Down') {
      selectedIndex =
        selectedIndex === fonts.length - 1 ? selectedIndex : selectedIndex + 1;
      evt.preventDefault();
      evt.stopPropagation();
    } else if (evt.key === 'Enter') {
      value = fonts[selectedIndex];
      evt.preventDefault();
      evt.stopPropagation();
      close();
    }
  }
</script>

<svelte:window on:click={onClick} on:keyup={onKeyUp} />

<div id="font-selector" bind:this={container} bind:clientWidth={inputWidth}>
  <input
    id="font-selector-input"
    type="text"
    readonly
    value={value.name}
    bind:this={input}
    on:focus={() => (open = true)}
  />

  {#if open}
    <div id="items-container" style:width={`${inputWidth}px`}>
      <ol>
        {#each fonts as font, index}
          <li
            class="font-item"
            class:selected-item={selectedIndex === index}
            style:font-family={font.family}
            on:focus={() => {
              selectedIndex = index;
            }}
            on:mouseover={() => {
              selectedIndex = index;
            }}
            on:click={() => {
              value = font;
              open = false;
            }}
          >
            {font.name}
          </li>
        {/each}
      </ol>
    </div>
  {/if}
</div>

<style>
  #font-selector {
    user-select: none;
  }

  .font-item {
    padding-left: 2px;
  }

  input {
    width: 100%;
    cursor: pointer;
  }

  .selected-item {
    background-color: rgb(101, 101, 206);
    color: white;
  }

  #items-container {
    position: absolute;
    top: 44px;
    background-color: white;
    padding: 0;
    font-size: 24px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #items-container ol {
    margin: 0;
    list-style: none;
    padding-left: 0;
  }

  /* start milligram */

  input[type='text'],
  input:not([type]) {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0.1rem solid #d1d1d1;
    border-radius: 0.4rem;
    box-shadow: none;
    box-sizing: inherit;
    height: 3.8rem;
    padding: 0.6rem 1rem 0.7rem;
    width: 100%;
  }

  input[type='text']:focus,
  input:not([type]):focus {
    border-color: var(--bg-color);
    outline: 0;
  }

  /* End milligram */
</style>
